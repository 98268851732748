<template>
  <el-form :model="addForm" label-width="200px" ref="addFormRef">
    <div style="margin-left: 20px;">
      <h3>开户信息</h3>
    </div>

    <!-- <el-form-item label="是否pos商户" required>
      <el-select v-model="addForm.pos" placeholder="请选择">
        <el-option v-for="item in shoppop" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item> -->

    <el-form-item label="入网商户类型" required>
      <el-select v-model="addForm.merchantType" placeholder="请选择">
        <el-option v-for="item in shopBusiness" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="行业类别" required>
      <div style="display:flex;">
        <div style="margin-right: 20px;">
          <el-select v-model="addForm.grandpaCode" placeholder="--请选择--" @focus="industryFocus()" @change="pullDown('1','grandpaCode')">
            <el-option v-for="item in rowpageOne" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin-right: 20px;">
          <el-select v-model="addForm.fatherCode" placeholder="--请选择--" @focus="industryFocus1(addForm.grandpaCode)" @change="pullDown('2','fatherCode')">
            <el-option v-for="item in rowpageTwo" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin-right: 20px;">
          <el-select v-model="addForm.mccCode" placeholder="--请选择--" @focus="industryFocus2(addForm.fatherCode)" @change="pullDown('3','mccCode')">
            <el-option v-for="item in rowpageThree" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </el-form-item>

    <el-form-item label="商户名称" required>
      <el-input v-model="addForm.merchantName" show-word-limit="show-word-limit" style="width: 500px" placeholder="和营业执照保持一致"></el-input>
    </el-form-item>

    <el-form-item label="商户简称" required>
      <el-input v-model="addForm.merchantShortName" show-word-limit="show-word-limit" style="width: 500px" placeholder="商户简称"></el-input>
    </el-form-item>

    <!-- <el-form-item label="注册名称" required>
      <el-input v-model="addForm.merchant_company" show-word-limit="show-word-limit" type="text" style="width: 500px" placeholder="和营业执照保持一致"></el-input>
    </el-form-item> -->

    <el-form-item label="所在省市区" required>
      <div style="display:flex;">
        <div style="margin-right: 20px;">
          <el-select v-model="addForm.provinceCode" placeholder="--选择省--" @focus="selectRegion(0,'shengArr')" @change="addressChange($event,'provinceCode','shengArr','provinceName')">
            <el-option v-for="item in shengArr" :key="item.id" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin-right: 20px;">
          <el-select v-model="addForm.cityCode" placeholder="--选择市--" @focus="selectRegion(1,'shiArr')" @change="addressChange($event,'cityCode','shiArr','cityName')">
            <el-option v-for="item in shiArr" :key="item.id" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin-right: 20px;">
          <el-select v-model="addForm.areaCode" placeholder="--选择区/县--" @focus="selectRegion(2,'quArr')" @change="addressChange($event,'areaCode','quArr','areaName')">
            <el-option v-for="item in quArr" :key="item.id" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </el-form-item>

    <el-form-item label="商户地址" required>
      <el-input type="text" placeholder="详细地址:不含省市区县字样" v-model="addForm.address" maxlength="30" show-word-limit>
      </el-input>
    </el-form-item>

    <el-form-item label="商户联系人" required>
      <el-input v-model="addForm.name" show-word-limit="show-word-limit" type="text" style="width: 500px"></el-input>
    </el-form-item>

    <!-- <el-form-item label="商户联系人邮箱" prop="merchant_email">
      <el-input v-model="addForm.merchant_email" show-word-limit="show-word-limit" type="text" style="width: 500px"></el-input>
    </el-form-item> -->

    <el-form-item label="商户联系人电话" required prop="mobile">
      <el-input v-model="addForm.mobile" show-word-limit="show-word-limit" type="text" style="width: 500px"></el-input>
    </el-form-item>
    <!-- 
    <el-form-item label="商户客服电话" prop="merchant_service_phone">
      <el-input v-model="addForm.merchant_service_phone" show-word-limit="show-word-limit" type="text" style="width: 500px"></el-input>
    </el-form-item> -->

    <div style="margin-left: 20px;">
      <h3>证件信息</h3>
    </div>

    <el-form-item label="营业执照">
      <file-pic :file='addForm.licensePic' @input='addForm.licensePic = $event'></file-pic>

    </el-form-item>
    <el-form-item label="营业执照名称">
      <el-input v-model="addForm.licenseFullName" show-word-limit="show-word-limit" type="text" style="width: 500px"></el-input>
    </el-form-item>
    <!-- 
    <el-form-item label="开户许可证照片">
      <file-pic :file='addForm.img_open_permits' @input='addForm.img_open_permits = $event'></file-pic>
    </el-form-item> -->

    <el-form-item label="执照证件号码">
      <el-input v-model="addForm.license" show-word-limit="show-word-limit" type="text" style="width: 700px" placeholder="营业执照注册号或者统一社会信用代码,一般以9开头,小微商户填写法人身份证号"></el-input>
    </el-form-item>

    <el-form-item label="营业执照地址">
      <el-input v-model="addForm.licenseAddress" show-word-limit="show-word-limit" type="text" style="width: 500px"></el-input>
    </el-form-item>

    <el-form-item label="营业执照开始日期">
      <el-date-picker type="date" placeholder="选择日期时间" v-model="addForm.licenseStart" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
      </el-date-picker>
    </el-form-item>

    <el-form-item label="营业执照结束日期">
      <el-input v-if="addForm.licenseEnd == '长期'" v-model="addForm.licenseEnd " show-word-limit="show-word-limit" type="text" style="width: 500px"></el-input>
      <el-date-picker v-else type="date" placeholder="选择日期时间" v-model="addForm.licenseEnd" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
      </el-date-picker>
      <el-checkbox class="mrl10" v-model="licenseEndChecked" @change="checkedClick(licenseEndChecked)">长期</el-checkbox>
    </el-form-item>
    <!-- 
    <el-form-item label="执照注册地址">
      <el-input v-model="addForm.merchant_service_phone" show-word-limit="show-word-limit" type="text" style="width: 700px" placeholder="执照注册地址"></el-input>
    </el-form-item> -->

    <!--   
    <el-form-item label="证件到期日期">
  
      <div class="block">
        <el-date-picker v-model="addForm.license_expire" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">

        </el-date-picker>
      </div>
    </el-form-item> -->

    <div style=" margin-left: 20px;">
      <h3>账号信息</h3>
    </div>

    <el-form-item label="结算账户类型" required>
      <el-select v-model="addForm.type" placeholder="--请选择--">
        <el-option v-for="item in shoptype" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="结算类型" required>
      <!-- <el-select v-model="addForm.legalFlag" placeholder="--请选择--"> -->
      <el-select v-model="addForm.legalFlag" placeholder="--请选择--">
        <el-option v-for="item in settlementtype" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item :label="addForm.legalFlag == 0 ? '结算人身份证正面' : '法人身份证正面'" required>
      <file-pic :file='addForm.idcardFrontPic' @input='addForm.idcardFrontPic = $event'></file-pic>
    </el-form-item>

    <el-form-item :label="addForm.legalFlag == 0 ? '结算人身份证反面' : '法人身份证反面'" required>
      <file-pic :file='addForm.idcardBackPic' @input='addForm.idcardBackPic = $event'></file-pic>
    </el-form-item>

    <el-form-item :label="addForm.legalFlag == 0 ? '结算人代表名称' : '法人代表名称'" required>
      <el-input v-model="addForm.legalName" show-word-limit="show-word-limit" style="width: 500px" placeholder="法人代表名称"></el-input>
    </el-form-item>

    <el-form-item :label="addForm.legalFlag == 0 ? '结算人身份证号' : '法人身份证号'" required prop="idCardNo">
      <el-input v-model="addForm.idCardNo" show-word-limit="show-word-limit" style="width: 500px" placeholder="法人身份证号"></el-input>
    </el-form-item>

    <el-form-item :label="addForm.legalFlag == 0 ? '结算人身份证开始日期' : '法人身份证开始日期'">

      <div class="block">
        <el-date-picker v-model="addForm.idCardStart" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>

    </el-form-item>

    <el-form-item :label="addForm.legalFlag == 0 ? '结算人身份证有效日期' : '法人身份证有效日期'">

      <div class="block">
        <el-date-picker v-model="addForm.idCardEnd" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>

    </el-form-item>

    <el-form-item label="入账银行卡开户名（结算人姓名/公司名）">
      <el-input v-model="addForm.holder" show-word-limit="show-word-limit" style="width: 500px"></el-input>
    </el-form-item>
    <el-form-item label="入账银行卡号">
      <el-input v-model="addForm.bankCardNo" show-word-limit="show-word-limit" style="width: 500px"></el-input>
    </el-form-item>

    <el-form-item label="银行卡正面" required>
      <file-pic :file='addForm.bankCardFrontPic' @input='addForm.bankCardFrontPic = $event'></file-pic>
    </el-form-item>

    <!-- <div v-if="this.addForm.type == 2">
      <file-pic :file='addForm.img_bankcard_a' @input='addForm.img_bankcard_a = $event'></file-pic>
    </div> -->

    <!-- <el-form-item label="入账银行预留手机号" prop="account_phone">
      <el-input v-model="addForm.account_phone" show-word-limit="show-word-limit" style="width: 500px"></el-input>
    </el-form-item> -->

    <el-form-item label="结算账户开户行">
      <el-select v-model="addForm.bank_no" placeholder="--请选择--" @focus="getBankList" @change="bankEndChange" clearable filterable>
        <el-option v-for="item in bankList" :key="item.id" :label="item.bank_name" :value="item.bank_id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="结算银户地址" required>
      <div style="display:flex;">
        <div style="margin-right: 20px;">
          <el-select filterable v-model="addForm.bank_province_code" placeholder="--选择省--" @focus="bankRegion(0,'bankShengArr')" @change="bankChange($event,'bank_province_code')">
            <el-option v-for="item in bankShengArr" :key="item.id" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin-right: 20px;">
          <!-- {{  bankShiArr}} -->
          <el-select filterable v-model="addForm.bank_city_code" placeholder="--选择市--" @focus="bankRegion(1,'bankShiArr')" @change="bankChange($event,'bank_city_code')">
            <el-option v-for="item in bankShiArr" :key="item.id" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

      </div>
    </el-form-item>

    <el-form-item label="结算户开户支行">
      <el-select v-model="addForm.unionpay" placeholder="--请选择--" @focus="getBankBranchList" filterable @change="bankSlect" clearable style="width: 500px;">
        <el-option v-for="item in bankBranchList" :key="item.id" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="入账非法人授权证明照片" v-if="addForm.legalFlag == 0">
      <file-pic :file='addForm.nonLegSettleAuthPic' @input='addForm.nonLegSettleAuthPic = $event'></file-pic>
    </el-form-item>

    <el-form-item label="对私账户身份证正面照片" v-if="addForm.legalFlag == 1">
      <file-pic :file='addForm.idcardFrontPic' @input='addForm.idcardFrontPic = $event'></file-pic>
    </el-form-item>

    <el-form-item label="对私账户身份证反面照片" v-if="addForm.legalFlag == 1">
      <file-pic :file='addForm.idcardBackPic' @input='addForm.idcardBackPic = $event'></file-pic>

    </el-form-item>

    <div style="margin-left: 20px;">
      <h3>资质证件照信息</h3>
    </div>
    <!-- 上传图片 -->
    <div style="display:flex;">
      <el-form-item label="商户门头照片">
        <file-pic :file='addForm.doorPic' @input='addForm.doorPic = $event'></file-pic>
      </el-form-item>

      <el-form-item label="内部前台照片">
        <file-pic :file='addForm.insidePic' @input='addForm.insidePic = $event'></file-pic>

      </el-form-item>

      <el-form-item label="收银台照片">
        <file-pic :file='addForm.cashierDeskPic' @input='addForm.cashierDeskPic = $event'></file-pic>
      </el-form-item>

    </div>

    <div class="flex" style="margin-left: 100px;">
      <div>
        <el-button type="primary" @click="submitCopy(addForm)">保存资料</el-button>
      </div>

      <div style="margin-left: 100px;">
        <el-button type="primary" @click="openAccount(addForm)">提交给乐刷审核</el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
import filePic from "@/components/common/filePic";
export default {
  components: {
    filePic,
  },
  props: {},
  data() {
    return {
      elDialogPicture: "",
      dialogImage: false,
      licenseEndChecked: false,
      bankList: [],
      rowpageOne: [],
      rowpageTwo: [],
      rowpageThree: [],
      industryList: [],
      shengArr: [], //
      shiArr: [],
      quArr: [],
      bankShengArr: [],
      bankShiArr: [],
      business1: [],
      business2: [],
      business3: [],
      bankBranchList: [],
      industrySearch: {
        uni1_id: "",
        uni2_id: "",
        uni3_id: "",
      },
      addressSearch: {
        provinceCode: "", //省
        cityCode: "",
        areaCode: "",
      },
      bankSearch: {
        city_code: "",
        sen_id: "",
      },
      bankForm: {
        bank_id: "",
        city_code: "",
        sen_id: "",
      },
      industryFrom: {
        uni1_id: "",
        uni2_id: "",
        uni3_id: "",
      },
      addForm: {
        merchantType: "", //1小薇2个体3企业
        merchantShortName: "", //简称
        merchantName: "", //   商户名称
        mccCode: "", //mcc
        grandpaCode: "",
        fatherCode: "",
        legalName: "", //   法人姓名
        credentialType: "", //     1身份证
        idCardNo: "", //身份证号码
        idcardFrontPic: "", // 身份证正面
        idcardBackPic: "", //身份证反面
        idcardHandPic: "", //手持身份证
        idCardStart: "", //开始日期
        idCardEnd: "", //结束日期
        name: "", //联系人
        mobile: "", // 手机号码
        provinceCode: "", // 省
        cityCode: "", // 市
        areaCode: "", //区
        address: "", //(地址
        type: "", // 1个人账户
        legalFlag: "", // 1法人结算 0非法人
        unionpay: "", //
        holder: "", // 持卡人
        bankCardNo: "", // 银行卡号
        bankCardFrontPic: "", // 银行卡照片
        insidePic: "", // 经营场所内设照片
        doorPic: "", //门头照
        cashierDeskPic: "", // 收银台,
        licenseFullName: "", //营业执照名称
        licenseAddress: "", //营业执照地址
        bank_name: "",
        licenseEnd: "", //营业执结束时间
        licenseStart: "", //营业执开始时间
        nonLegSettleAuthPic: "",
        merchant_province_code: "",
        merchant_province: "",
        merchant_city: "",
        merchant_city_code: "",
        unionpayName: "",
      },
      // addRules: {
      //   name: [{ required: true, message: "请输入店铺名称", trigger: "blur" }],
      //   legalIdnum: [
      //     { required: true, message: "请输入正确的身份证", trigger: "blur" },
      //     { validator: checkIDCard, trigger: "blur" },
      //   ],
      //   merchant_id_no: [
      //     { required: true, message: "请输入正确的身份证", trigger: "blur" },
      //     { required: true, validator: checkIDCard, trigger: "blur" },
      //   ],
      //   merchant_email: [
      //     { required: true, message: "请输入正确的邮箱", trigger: "blur" },
      //     { validator: email, trigger: "blur" },
      //   ],
      //   merchant_person: [
      //     { required: true, message: "请输入正确手机号码", trigger: "blur" },
      //     { required: true, validator: checkMobile, trigger: "blur" },
      //   ],
      //   merchant_phone: [
      //     { required: true, message: "请输入正确手机号码", trigger: "blur" },
      //     { required: true, validator: checkMobile, trigger: "blur" },
      //   ],
      //   merchant_service_phone: [
      //     { required: true, message: "请输入正确手机号码", trigger: "blur" },
      //     { required: true, validator: checkMobile, trigger: "blur" },
      //   ],
      //   account_phone: [
      //     { required: true, message: "请输入正确手机号码", trigger: "blur" },
      //     { required: true, validator: checkMobile, trigger: "blur" },
      //   ],
      // }, //添加学生的正则
      licensetype: [
        {
          id: 0,
          name: "营业执照(该类型已停用)",
        },
        {
          id: 1,
          name: "三证合一(请使用该类型)",
        },
        {
          id: 2,
          name: "手持身份证",
        },
      ],
      settlementtype: [
        {
          id: 1,
          name: "法人结算",
        },
        {
          id: 0,
          name: "非法人结算",
        },
      ],
      shoppop: [
        {
          id: "1",
          name: "是",
        },
        {
          id: "2",
          name: "否",
        },
      ],
      shoptype: [
        {
          id: 1,
          name: "个人账户",
        },
        {
          id: 2,
          name: "公司账户",
        },
      ],
      shopBusiness: [
        {
          id: "0",
          name: "请选择入网商户类型",
        },
        {
          id: "1",
          name: "小微商户",
        },
        {
          id: "2",
          name: "个体商户",
        },
        {
          id: "3",
          name: "企业商户",
        },
      ],

      // 状态，1=正常，0=禁用，2删除, 3=审核中
    };
  },

  created() {
    this.getList();
    this.industryFocus();
    this.getBankList();
  },
  mounted() {},
  computed: {},

  methods: {
    checkedClick(val) {
      if (val) {
        this.addForm.licenseEnd = "长期";
      }
      if (!val) {
        this.addForm.licenseEnd = "";
      }
    },
    // 传银行名字
    bankEndChange(value) {
      // console.log("this.bankList", this.bankList);
      const select = this.bankList.find((i) => i.bank_id == value);
      // console.log("select", select);
      this.addForm.bank_name = select.bank_name;
    },
    async getList() {
      const { data } = await this.$Api2.leshuaInfo();

      console.log("乐刷支付", data);

      const {
        provinceCode,
        provinceName,
        cityCode,
        cityName,
        areaCode,
        areaName,
        bank_name,
        unionpay,
        unionpayName,
        bank_province,
        bank_province_code,
        bank_city_code,
        bank_city,
        bank_no,
      } = data;
      if (data.licenseEnd == "长期") {
        this.licenseEndChecked = true;
      }
      // console.log("data.fatherCode", data.fatherCode);
      this.addForm = data;
      // this.addForm.fatherCode = data.fatherCode.toString();
      this.addForm.fatherCode = data.fatherCode;

      this.shengArr = [
        {
          value: provinceCode,
          label: provinceName,
        },
      ];
      this.shiArr = [
        {
          value: cityCode,
          label: cityName,
        },
      ];
      this.quArr = [
        {
          value: areaCode,
          label: areaName,
        },
      ];

      //   this.bankList = [
      //     {
      //       bank_id: bank_no,
      //       bank_name: bank_name,
      //     },
      //   ];
      // this.business1 = [
      //   {
      //     firstname: grandpaText,
      //     id: grandpaCode,
      //   },
      // ];
      // this.business2 = [
      //   {
      //     secondname: business_second,
      //     id: fatherCode,
      //   },
      // ];
      // this.business3 = [
      //   {
      //     thirdname: business_name,
      //     code: mccCode,
      //   },
      // ];
      this.bankShengArr = [
        {
          label: bank_province,
          value: bank_province_code,
        },
      ];
      this.bankShiArr = [
        {
          label: bank_city,
          value: bank_city_code,
        },
      ];
      this.bankBranchList = [
        {
          label: unionpayName,
          value: unionpay,
        },
      ];
      // 银行名字
      this.bankList = [
        {
          bank_name: bank_name,
          bank_id: bank_no,
        },
      ];
    },
    // 银行类型列表
    async getBankList() {
      const { data } = await this.$Api2.leshuaBankList();
      this.bankList = data;
      // console.log("银行列表", data);
    },
    // 选择事件
    bankSlect(bank_no) {
      // console.log("bank_no", bank_no);
      const slectBank = this.bankBranchList.find((i) => i.value == bank_no);
      // console.log("获取选择的银行数据", this.bankBranchList);
      // console.log("slectBank", slectBank);
      this.addForm.unionpayName = slectBank.label;
    },
    // 银行支行
    async getBankBranchList() {
      // console.log(this.addForm.bank_no, "银行id");
      // console.log(this.addForm.bank_city_code, "银行id");
      const { bank_no, bank_city_code } = this.addForm;
      const { data } = await this.$Api2.leshuaBankBranchList({
        bank_id: bank_no,
        city_code: bank_city_code,
      });
      // console.log("银行支行列表", data);

      this.recLoading = false;
      this.bankBranchList = data.map((item) => {
        return {
          value: item.branch_id,
          label: item.branch_name,
        };
      });
      // console.log("e.branch_id;", data.branch_id);
    },
    // 行业类目
    async industryFocus(index, field) {
      const { code, data } = await this.$Api2.leshuaMccData();

      this.rowpageOne = data.data?.map((item) => {
        return {
          value: item.grandpaCode,
          label: item.grandpaText,
        };
      });
      console.log(this.rowpageOne, "第一个下拉框");

      this.recLoading = false;
      this.industryFocus1(this.addForm.grandpaCode);
      this.industryFocus2(this.addForm.fatherCode);
      // this[field] = data.data;
    },
    async industryFocus1(id) {
      // console.log("二级拿到的id", id);
      const { code, data } = await this.$Api2.leshuaMccData({
        grandpaCode: id,
      });
      this.rowpageTwo = data.data.map((item) => {
        return {
          value: item.fatherCode,
          label: item.fatherText,
        };
      });
      // console.log(this.rowpageTwo, "第二个下拉框");
    },
    async industryFocus2(id) {
      const { code, data } = await this.$Api2.leshuaMccData({
        fatherCode: id,
      });

      this.rowpageThree = data.data.map((item) => {
        return {
          value: item.mccCode,
          label: item.mccText,
        };
      });

      // console.log(this.rowpageThree, "第三个下拉框");
    },
    // 下拉框选择事件
    pullDown(value, field) {
      // console.log("value1", value);
      // console.log("field1", field);
      if (field == "grandpaCode") {
        this.addForm.fatherCode = "";
        this.addForm.mccCode = "";
        this.industryFocus1(this.addForm.grandpaCode);
      }
      if (field == "fatherCode") {
        this.addForm.mccCode = "";
        this.industryFocus2(this.addForm.fatherCode);
      }
    },

    bankChange(value, field) {
      // console.log("bankChangefield", field);
      if (field == "bank_province_code") {
        ["bank_city_code", "bank_city"].forEach((i) => {
          this.addForm[i] = "";
        });
        const select = this.bankShengArr.find((i) => i.value == value);
        // console.log("选择了区123", select);
        this.addForm.bank_province = select.label;
      }
      if (field == "bank_city_code") {
        const select = this.bankShiArr.find((i) => i.value == value);
        // console.log("选择了区123", select);
        this.addForm.bank_city = select.label;
      }
      // this.addForm.bank_province_code = "";
      // this.addForm.bank_city = "";
      this.addForm[field] = value;
    },

    async bankRegion(index, field) {
      // index索引
      // field 需要赋值的字段
      if (index == 0) {
        [
          "bank_province",
          "bank_province_code",
          "bank_city_code",
          "bank_city",
        ].forEach((i) => {
          this.addForm[i] = "";
        });
      }
      const { bank_province_code: bank_id } = this.addForm;
      if (!bank_id && index == 1) return this.$message.error("请选择省");
      // provinceCode 传了省的id，获取市
      // cityCode  传了市的id，获取县
      this.recLoading = true;
      // console.log("此时此刻银行列表", this.addForm.unionpay);

      const { code, data } = await this.$Api2.leshuaBankAreaList({
        merchant_province_code: this.addForm.bank_province_code, // 传省id
      });

      this[field] = data.map((i) => {
        const {
          merchant_province_code,
          merchant_province,
          merchant_city,
          merchant_city_code,
        } = i;
        return {
          value: merchant_province_code || merchant_city_code,
          label: merchant_province || merchant_city,
        };
      });
    },
    addressChange(value, field, arr, fieldName) {
      // console.log("value11111", value);
      if (field == "provinceCode") {
        ["cityName", "cityCode", "areaName", "areaCode"].forEach((i) => {
          this.addForm[i] = "";
        });
      }
      if (field == "cityCode") {
        ["areaName", "areaCode"].forEach((i) => {
          this.addForm[i] = "";
        });
      }
      const selectAddress = this[arr].find((i) => i.value == value);
      // console.log("选择的", selectAddress);
      // Object.assign(this.addForm, selectAddress);
      this.addForm[fieldName] = selectAddress.label;
      //   this.addressSearch[field] = selectAddress.value;
      this.addForm[field] = selectAddress.value;
      // console.log(" this.addForm this.addForm", this.addForm);
    },
    // 区列表请求
    async selectRegion(index, field) {
      const { cityCode, provinceCode } = this.addForm;
      // console.log("this.addForm", this.addForm);
      if (index == 0) {
        this.addForm.cityCode = "";
        this.addForm.provinceCode = "";
        this.addForm.areaCode = "";
      }
      if (index == 1 && !provinceCode) return this.$message.error("请选择省");

      if (index == 2) {
        if (!cityCode) return this.$message.error("请选择市");
      }
      this.recLoading = true;
      // console.log("省id", this.addForm.provinceCode);
      // console.log("市id", this.addForm.cityCode);
      let argument = "";
      switch (field) {
        case "shengArr":
          argument = {};
          break;
        case "shiArr":
          argument = {
            parentAreaCode: this.addForm.provinceCode,
          };
          break;
        case "quArr":
          argument = {};
          argument = {
            parentAreaCode: this.addForm.cityCode,
          };
          break;
      }
      // console.log("提交的", argument);
      // return;
      const { code, data } = await this.$Api2.leshuaAreaListData(argument);
      // console.log("请求的", data);
      this[field] = data.map((i) => {
        const { areaCode, areaName } = i;
        return {
          value: areaCode,
          label: areaName,
        };
      });

      this.recLoading = false;
    },
    delBusinessTime(index, type) {
      if (type == "add") {
        this.addForm.business_time.splice(index, 1);
      } else {
        this.editForm.business_time.splice(index, 1);
      }
    },

    // 提交事件
    async submitCopy(row) {
      // console.log("this.add", this.addForm);

      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          // console.log("表单校验成功");
        }
      });

      this.recLoading = true;

      const { code, data } = await this.$Api2.saveLeshuaData(this.addForm);

      if (code == 200) {
        this.$message.success("保存成功");
      } else {
        this.$message.error("保存失败");
      }
    },
    // 去开户提交事件
    async openAccount(row) {
      console.log("row", row);
      const { shop_id } = row;
      this.recLoading = true;
      const data = await this.$Api2.leshuaRegister({
        shop_id: shop_id,
      });
      console.log("提交成功", data);
      if (data.code == 200) {
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
      this.recLoading = false;

      this.getList();
    },
    // 上传图片
    uploadImgFile(e, field) {
      // field 要赋值的字段
      console.log("要赋值的字段", field);

      let file = e.target.files[0];
      if (!file) return;

      let imageName = e.target.name;
      this.uploading = true;
      this.uploadImage(file, imageName, field);
      // 这里传了三个参数
    },

    uploadImage(file, imageName, field) {
      this.$request.get({
        url: `/upload-config`,
        params: { ext: file.type },
        success: (res) => {},
      });
    },
    zoomIn(url) {
      console.log("1111111", url);
      this.elDialogPicture = url;
      this.dialogImage = true;
    },
    deleteImg(field) {
      this.$confirm("确认删除图片？")
        .then((_) => {
          this.addForm[field] = "";
          var file = document.getElementsByName("file");
          if (file.length) file[0].value = "";
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang='scss' scoped>
</style>